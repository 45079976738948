import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';
import FilePlayer from 'react-player/file';
import { useToast } from 'components/ToastProvider';

interface Props {
  src: string;
  onPlaybackEnded?: () => void;
  onPlaybackPaused?: (timestamp: number) => void;
  onPlaybackProgress?: (timestamp: number) => void;
  timestamp?: number;
  blockSeekForward?: boolean;
}

const VideoPlayer: React.FC<Props> = React.memo(
  ({ src, onPlaybackEnded, onPlaybackPaused, onPlaybackProgress, timestamp, blockSeekForward }) => {
    const videoElement = useRef<FilePlayer | null>(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [seekTime, setSeekTime] = useState(0);
    const [isInitialized, setisInitialized] = useState(false);
    const [timer, setTimer] = useState<any>();
    const { errorMessage } = useToast();
    const internalPlayer = videoElement.current?.getInternalPlayer() as HTMLVideoElement;

    useEffect(() => {
      if (internalPlayer) {
        internalPlayer.oncontextmenu = () => false;
      }
    }, [internalPlayer]);

    const onSeek = (seconds: number) => {
      setSeekTime(seconds);
    };

    const onPause = () => {
      clearTimeout(timer);
      if (onPlaybackPaused && videoElement.current) {
        onPlaybackPaused(videoElement.current.getCurrentTime());
      }
    };

    const onProgress = (timestamp: number) => {
      setCurrentTime(timestamp);

      if (timestamp <= 0) return;

      if (onPlaybackProgress && !timer) {
        clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            onPlaybackProgress(timestamp);
            setTimer(null);
          }, 60 * 1000)
        );
      }
    };

    const onReady = () => {
      if (!videoElement.current) return;

      // Play video from previously saved timestamp if it exists
      if (!isInitialized) {
        if (timestamp && timestamp > 0) {
          // Video is watched, let the parent componnt know
          if (timestamp === videoElement.current.getDuration() && onPlaybackEnded) {
            onPlaybackEnded();
          }

          setCurrentTime(timestamp);
          videoElement.current.seekTo(timestamp);
        }

        setisInitialized(true);
      } else {
        // Handle seeking if it is blocked
        if (blockSeekForward && Math.round(seekTime) > Math.round(currentTime)) {
          errorMessage('Seeking forward is not allowed!');
          // Reset seeking
          videoElement.current.seekTo(currentTime);
        }
      }
    };

    return (
      <div className="rounded-lg shadow-xl">
        <ReactPlayer
          pip={false}
          className="video-player"
          url={src}
          controls
          width="100%"
          height="100%"
          ref={videoElement}
          onProgress={(e) => {
            onProgress(e.playedSeconds);
          }}
          onEnded={onPlaybackEnded}
          onPause={onPause}
          onReady={onReady}
          onSeek={onSeek}
          onError={(error: any, data?: any) =>
            console.error('VIDEO ERROR', { message: error?.target?.error?.message, error, data })
          }
          config={{
            attributes: { controlsList: 'nodownload noremoteplayback noplaybackrate', disablePictureInPicture: true }
          }}
        />
      </div>
    );
  }
);

export default VideoPlayer;
