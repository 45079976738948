import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAppState } from 'state';

function PrivateRoute({ component: Component, ...rest }: any) {
  const { state } = useAppState();
  const location = useLocation();

  return (
    // Redirect to login page if not authenticated
    <Route
      {...rest}
      render={(props) =>
        state.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location.pathname }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
