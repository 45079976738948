import React from 'react';
import classnames from 'classnames';
import toast, { Toaster } from 'react-hot-toast';

type ToastType = 'success' | 'error' | 'info' | 'warning';

interface ToastProps {
  type: ToastType;
  message: string;
}

const ToastProvider = () => {
  return <Toaster position="bottom-center" gutter={0} />;
};

const Toast = (props: ToastProps) => {
  return (
    <div
      className={classnames(
        'p-3 md:p-4 mb-6 rounded border-2',
        props.type === 'error' && 'text-red-700 bg-red-100 border-red-300',
        props.type === 'info' && 'text-blue-700 bg-blue-100 border-blue-300',
        props.type === 'success' && 'text-green-700 bg-green-100 border-green-300',
        props.type === 'warning' && 'text-orange-700 bg-orange-100 border-orange-300'
      )}>
      <h3 className="font-semibold">{props.message}</h3>
    </div>
  );
};

export const useToast = (duration: number = 5000) => {
  return {
    successMessage: (message: string) =>
      toast.custom(<Toast message={message} type="success" />, { id: 'success', duration }),
    errorMessage: (message: string) =>
      toast.custom(<Toast message={message} type="error" />, { id: 'error', duration }),
    infoMessage: (message: string) => toast.custom(<Toast message={message} type="info" />, { id: 'info', duration }),
    warningMessage: (message: string) =>
      toast.custom(<Toast message={message} type="warning" />, { id: 'warning', duration })
  };
};

export default ToastProvider;
