import React from 'react';
import classnames from 'classnames';

interface Props {
  children: React.ReactNode;
  type: 'button' | 'submit' | 'reset';
  schema?: 'primary' | 'simple';
}

const Button: React.FunctionComponent<Props & JSX.IntrinsicElements['button']> = ({
  type,
  className,
  schema,
  children,
  disabled,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classnames(
        'border font-semibold focus:outline-none px-4 py-3 leading-6 rounded border-gray-300 bg-white shadow-sm hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none whitespace-nowrap',
        className,
        {
          'text-gray-800 hover:text-gray-800': schema !== 'primary',
          'border-blue-700 bg-blue-700 text-white hover:text-white hover:bg-blue-800 hover:border-blue-800 focus:ring-blue-500 focus:ring-opacity-50 active:bg-blue-700 active:border-blue-700':
            schema === 'primary',
          'cursor-not-allowed ': disabled
        }
      )}
      disabled={disabled}
      {...rest}>
      {children}
    </button>
  );
};

export default Button;
