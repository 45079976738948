import { PuzzleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/Button';
import PageHeader from 'components/SidebarLayout/PageHeader';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CourseQuestion } from 'types/course';

interface Params {
  sku: string;
  orderNumber: string;
}

const QuizPage = () => {
  const [questions, setQuestions] = useState<CourseQuestion[]>();
  const [activeQuestion, setActiveQuestion] = useState<CourseQuestion>();
  const { push } = useHistory();
  const { sku, orderNumber } = useParams<Params>();

  useEffect(() => {
    axios.get<CourseQuestion[]>(`course/questions/${sku}`).then((response) => {
      const questions = response.data;

      if (questions && questions.length > 0) {
        setQuestions(questions);
        setActiveQuestion(questions[0]);
      }
    });
  }, [sku]);

  const updateQuestionAnswer = (questionId: string, answerId: string) => {
    const updatedQuestions = questions?.map((question) => {
      if (question.id === questionId) {
        question.answers = question.answers.map((answer) => {
          if (answer.id === answerId) {
            answer.selected = question.isSingleChoice ? true : !answer.selected;
          } else if (question.isSingleChoice) {
            // If single choice mark all other answers as false
            answer.selected = false;
          }

          return answer;
        });
      }

      return question;
    });

    setQuestions(updatedQuestions);
  };

  if (!activeQuestion || !questions) return <Fragment />;

  return (
    <Fragment>
      <PageHeader Icon={PuzzleIcon}>
        Course Quiz, Question: {activeQuestion.sortNumber}/{questions.length}
      </PageHeader>
      <QuizQuestionView question={activeQuestion} onAnswer={updateQuestionAnswer} />
      <div className="flex sm:space-x-2 space-y-2 sm:space-y-0 mt-8 flex-col sm:flex-row">
        {activeQuestion.sortNumber < questions.length && (
          <Button
            type="button"
            schema="primary"
            onClick={() => setActiveQuestion(questions[activeQuestion.sortNumber])}>
            Answer
          </Button>
        )}
        {activeQuestion.sortNumber === questions.length && (
          <Button
            type="button"
            schema="primary"
            onClick={() => push(`/course/${sku}/quiz/${orderNumber}/results`, { questions: questions })}>
            Answer and check results
          </Button>
        )}

        {activeQuestion.sortNumber > 1 && (
          <Button
            type="button"
            schema="simple"
            onClick={() => setActiveQuestion(questions[activeQuestion.sortNumber - 2])}>
            Previous question
          </Button>
        )}

        {/* <Button type="button" schema="simple" onClick={() => push(`/course/${sku}`, { returning: true })}>
          Back to video
        </Button> */}
      </div>
    </Fragment>
  );
};

const QuizQuestionView = (props: {
  question: CourseQuestion;
  onAnswer: (questionId: string, answerId: string) => void;
}) => {
  return (
    <div className="space-y-4 xl:w-3/4 2xl:w-3/5 rounded border bg-white p-4">
      <div className="font-medium sm:text-lg">{`${props.question.sortNumber}. ${props.question.text}`}</div>
      <div className="space-y-2 pl-2">
        {props.question.answers.map((answer) => {
          return (
            <label
              className="flex items-center cursor-pointer"
              key={`question-${props.question.sortNumber}-answer-${answer.id}`}>
              <input
                type={props.question.isSingleChoice ? 'radio' : 'checkbox'}
                name={`question-${props.question.sortNumber}-answer`}
                checked={answer.selected}
                value={answer.selected?.toString()}
                onChange={() => props.onAnswer(props.question.id, answer.id)}
                className={classNames(
                  'border border-blue-500 h-4 w-4 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50',
                  { rounded: !props.question.isSingleChoice }
                )}
              />
              <span className="ml-2">
                {answer.letter && <span className="font-medium">{answer.letter}. </span>}
                {answer.text}
              </span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default QuizPage;
