import classNames from 'classnames';
import { OrderStatus } from 'types/order';

interface Props {
  status: OrderStatus;
}
const OrderStatusIndicator = (props: Props) => {
  const getStatus = () => {
    switch (props.status) {
      case OrderStatus.new:
        return { label: 'New', className: 'text-gray-600 bg-gray-200' };
      case OrderStatus.delivered:
        return { label: 'Delivered', className: 'text-green-700 bg-green-200' };
      case OrderStatus.prepared:
        return { label: 'Prepared', className: 'text-teal-700 bg-teal-200' };
      case OrderStatus.processing:
        return { label: 'Processing', className: 'text-orange-700 bg-orange-200' };
      case OrderStatus.shipped:
        return { label: 'Shipped', className: 'text-teal-700 bg-teal-200' };
    }
  };
  return (
    <div
      className={classNames(
        'font-semibold inline-flex px-2 py-1 leading-4 text-xs rounded-full h-6',
        getStatus().className
      )}>
      {getStatus().label}
    </div>
  );
};

export default OrderStatusIndicator;
