import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { ExclamationIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import Order from 'types/order';
import PageHeader from 'components/SidebarLayout/PageHeader';
import { useHistory } from 'react-router';
import OrderStatusIndicator from 'components/OrderStatusIndicator';
import { formatDate } from 'utilities/datetime';
import { formatMoney } from 'utilities';
import TrackingLink from 'components/TrackingLink';
import usePageLoad from 'components/PageLoad/usePageLoad';
import PageLoadingArea from 'components/PageLoad';

const OrderHistoryPage = () => {
  const [orders, setOrders] = useState<Order[] | undefined>(undefined);
  const { push } = useHistory();
  const { trackRequest } = usePageLoad();

  useEffect(() => {
    trackRequest(
      axios.get<Order[]>(`order`).then((response) => {
        const orders = response.data;

        if (orders) {
          setOrders(orders);
        }
      })
    );
  }, [trackRequest]);

  return (
    <Fragment>
      <PageHeader Icon={ShoppingBagIcon}>Order history</PageHeader>
      <PageLoadingArea>
        {orders && orders.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 lg:gap-6">
            {orders.map((order) => {
              const orderNumber = order.isDistributorOrder ? `D${order.orderNumber}` : order.orderNumber;
              return (
                <button
                  key={order.orderNumber}
                  className="flex flex-col border-gray-300 hover:border-gray-400 border rounded shadow-sm bg-white overflow-hidden p-3 lg:p-4"
                  onClick={(e) => {
                    const target = e.target as HTMLElement;
                    if (!(target instanceof HTMLAnchorElement)) push(`order-preview/${orderNumber}`);
                  }}>
                  <div className="w-full flex flex-row justify-between items-center">
                    <h3 className="text-lg font-medium">#{orderNumber}</h3>
                    <OrderStatusIndicator status={order.status} />
                  </div>
                  <span className="text-sm text-gray-500">{formatDate(order.createdAt)}</span>
                  <div className="flex flex-col flex-grow text-left space-y-1 my-3 text-sm">
                    {order.trackingNumber && (
                      <div>
                        <span className="font-semibold text-gray-600">Tracking: </span>
                        <TrackingLink trackingNumber={order.trackingNumber} />
                      </div>
                    )}
                    {order.discount && (
                      <div>
                        <span className="font-semibold text-gray-600">Discount: </span>
                        {order.discount}
                      </div>
                    )}
                  </div>
                  <span className="font-semibold text-lg">{formatMoney(order.total)}</span>
                </button>
              );
            })}
          </div>
        )}
        {orders && orders.length === 0 && (
          <div className="p-4 md:p-5 rounded text-orange-700 bg-orange-100">
            <div className="flex items-center">
              <ExclamationIcon className="inline-block w-5 h-5 mr-3 flex-none text-orange-500" />
              <h3 className="flex-grow">
                You have not ordered any items. You can order some in our{' '}
                <a target="blank" href="https://bstrong.training/" className="underline">
                  shop
                </a>
                .
              </h3>
            </div>
          </div>
        )}
      </PageLoadingArea>
    </Fragment>
  );
};

export default OrderHistoryPage;
