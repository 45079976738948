import moment from 'moment';

/** Get date in specified format */
export const format = (date?: Date, format?: string) => {
  return date ? moment(date).format(format) : '';
};

export const formatDate = (date?: Date) => {
  return date ? moment(date).format('MM.DD.YYYY') : '';
};
