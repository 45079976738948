/* eslint-env browser */
import { useEffect } from 'react';

export const useOnOutsideClick = (
    selector: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler: (evt: any) => void
) => {
    useEffect(() => {
        const onOutsideHandler = (evt: Event) => {
            const target = evt.target as HTMLElement;
            if (!target.closest(selector)) {
                handler(evt);
            }
        };
        document.addEventListener('click', onOutsideHandler);
        return () => document.removeEventListener('click', onOutsideHandler);
    }, [selector, handler]);
};

export default useOnOutsideClick;
