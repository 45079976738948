import classNames from 'classnames';
import React, { useState } from 'react';
import { isMobile } from 'utilities';
import logoDark from 'media/logo-dark.png';
import {
  AcademicCapIcon,
  ExclamationIcon,
  FilmIcon,
  InformationCircleIcon,
  LightBulbIcon,
  LockOpenIcon,
  MenuAlt1Icon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  XIcon
} from '@heroicons/react/outline';
import { Link, matchPath, Switch, useHistory, useLocation } from 'react-router-dom';
import { useAppState } from 'state';
import { clearAuthToken } from 'utilities/http';
import OrderHistoryPage from 'pages/OrderHistoryPage';
import PrivateRoute from 'components/PrivateRoute';
import OrderPreviewPage from 'pages/OrderPreviewPage';
import CertificatesPage from 'pages/CertificatesPage';
import DropdownMenu from 'components/DropdownMenu';
import CoursePage from 'pages/CoursesPage/CoursePage';
import QuizPage from 'pages/CoursesPage/QuizPage';
import QuizResultsPage from 'pages/CoursesPage/QuizResultsPage';
import SurveyPage from 'pages/CoursesPage/SurveyPage';
import SurveyResultPage from 'pages/CoursesPage/SurveyResultPage';
import CoursesPage from 'pages/CoursesPage';

interface SidebarItem {
  name: string;
  path: string[];
  Icon: React.ComponentType<any>;
}

const sidebarItems: SidebarItem[] = [
  { name: 'Order history', path: ['/', '/order-preview/:orderNumber'], Icon: ShoppingBagIcon },
  { name: 'Certifications', path: ['/certificates'], Icon: AcademicCapIcon },
  {
    name: 'Courses',
    path: [
      '/courses',
      '/course/:sku',
      '/course/:sku/quiz/:orderNumber/results',
      '/course/:sku/quiz/:orderNumber',
      '/course/:sku/survey/:orderNumber/results',
      '/course/:sku/survey/:orderNumber'
    ],
    Icon: FilmIcon
  }
];

const SidebarLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(!isMobile);
  const [showBstrongWorksMenu, setShowBstrongWorksMenu] = useState<boolean>(false);
  const [showEducationMenu, setShowEducationMenu] = useState<boolean>(false);
  const [showAboutMenu, setShowAboutMenu] = useState<boolean>(false);
  const { dispatch } = useAppState();
  const { push } = useHistory();
  const location = useLocation();

  const logout = () => {
    clearAuthToken();
    dispatch({ type: 'LOG_OUT' });
    push('/login'); //  Redirect to login page
  };

  const activeSidebarItem =
    sidebarItems.find((item) => {
      return !!matchPath(location.pathname, {
        path: item.path,
        exact: true
      });
    }) || sidebarItems[0];

  return (
    <div
      className={classNames('flex flex-col mx-auto w-full min-h-screen bg-blue-50 bg-opacity-40 pb-16', {
        'md:pl-64': sidebarOpen
      })}>
      <nav
        id="page-sidebar"
        className={classNames(
          'flex flex-col fixed top-0 left-0 bottom-0 w-full md:w-64 h-full bg-sidebar-light border-r border-gray-200 z-50 transform transition-transform duration-500 ease-out',
          {
            'translate-x-0': sidebarOpen,
            '-translate-x-full': !sidebarOpen
          }
        )}
        aria-label="Main Sidebar Navigation">
        <div className="flex-none flex items-center justify-between md:justify-center px-7 w-full">
          <img src={logoDark} className="inline-block1 h-20 mt-6" alt="Logo" />
          <div className="md:hidden">
            <button
              type="button"
              className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 -mr-6 mt-6 leading-5 text-sm rounded border-transparent text-red-600 hover:text-red-400 focus:ring focus:ring-red-500 focus:ring-opacity-50 active:text-red-600"
              onClick={() => setSidebarOpen(false)}>
              <XIcon className="inline-block w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="overflow-y-auto">
          <div className="px-4 py-10 w-full">
            <nav className="space-y-1">
              <div className="px-3 pt-5 pb-2 text-xs font-medium uppercase tracking-wider text-gray-500">Customer</div>
              {sidebarItems.map((item) => {
                return (
                  <Link
                    key={item.name}
                    to={item.path[0]}
                    onClick={() => {
                      const isMobileSidebar = window.matchMedia('(max-width: 768px)').matches;
                      // On navigation close sidebar on mobile
                      if (isMobileSidebar) {
                        setSidebarOpen(false);
                      }
                    }}
                    className={classNames(
                      'flex items-center space-x-3 px-3 font-medium rounded text-gray-700 text-md',
                      { ' bg-gray-100': activeSidebarItem.name === item.name }
                    )}>
                    <span className="flex-none flex items-center opacity-60">
                      <item.Icon className="inline-block w-5 h-5" />
                    </span>
                    <span className="py-2 flex-grow">{item.name}</span>
                  </Link>
                );
              })}

              <div className="px-3 pt-8 pb-2 text-xs font-medium uppercase tracking-wider text-gray-500">
                <hr className="mt-4 mb-8 -mx-3" />
                Go B Strong
              </div>

              <a
                href="https://bstrong.training/pages/product-2"
                target="blank"
                className="flex items-center space-x-3 px-3 font-medium rounded text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-50">
                <span className="flex-none flex items-center opacity-60">
                  <ShoppingCartIcon className="inline-block w-5 h-5 text-green-500" />
                </span>
                <span className="py-2 flex-grow">Shop</span>
              </a>
              <span
                className="flex items-center space-x-3 px-3 font-medium rounded cursor-pointer text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-50"
                onClick={() => setShowBstrongWorksMenu(true)}>
                <span className="flex-none flex items-center opacity-60">
                  <LightBulbIcon className="inline-block w-5 h-5 text-yellow-500" />
                </span>
                <span className="py-2 flex-grow">B Strong Works</span>
              </span>
              {showBstrongWorksMenu && (
                <DropdownMenu
                  id="b-strong-works-menu"
                  menuItems={[
                    { name: 'How It Works?', url: 'https://bstrong.training/pages/how-it-works' },
                    { name: 'Why B Strong?', url: 'https://bstrong.training/pages/why-b-strong' },
                    { name: 'Success Stories', url: 'https://bstrong.training/pages/success-stories' }
                  ]}
                  onClose={() => setShowBstrongWorksMenu(false)}
                />
              )}

              <a
                href="https://bstrong.training/products/bfr-theory-and-application"
                target="blank"
                className="flex items-center space-x-3 px-3 font-medium rounded text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-50">
                <span className="flex-none flex items-center opacity-60">
                  <FilmIcon className="inline-block w-5 h-5 text-gray-800" />
                </span>
                <span className="py-2 flex-grow">Courses</span>
              </a>
              <span
                className="flex items-center space-x-3 px-3 font-medium rounded cursor-pointer text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-50"
                onClick={() => setShowEducationMenu(true)}>
                <span className="flex-none flex items-center opacity-60">
                  <AcademicCapIcon className="inline-block w-5 h-5 text-indigo-500" />
                </span>
                <span className="py-2 flex-grow">Education</span>
              </span>
              {showEducationMenu && (
                <DropdownMenu
                  id="education-menu"
                  menuItems={[
                    { name: 'BFR Training Research?', url: 'https://bstrong.training/pages/bfr-training-research' },
                    { name: 'FAQs', url: 'https://bstrong.training/pages/faqs' },
                    { name: 'Blogs', url: 'https://bstrong.training/pages/blogs' },
                    { name: 'Guidance App', url: 'https://bstrong.training/pages/guidance-app' }
                  ]}
                  onClose={() => setShowEducationMenu(false)}
                />
              )}

              <a
                href="https://bstrong.training/pages/safety"
                target="blank"
                className="flex items-center space-x-3 px-3 font-medium rounded text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-50">
                <span className="flex-none flex items-center opacity-60">
                  <ExclamationIcon className="inline-block w-5 h-5 text-orange-500" />
                </span>
                <span className="py-2 flex-grow">Safety</span>
              </a>
              <span
                className="flex items-center space-x-3 px-3 font-medium rounded cursor-pointer text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-50"
                onClick={() => setShowAboutMenu(true)}>
                <span className="flex-none flex items-center opacity-60">
                  <InformationCircleIcon className="inline-block w-5 h-5 text-blue-500" />
                </span>
                <span className="py-2 flex-grow">About</span>
              </span>
              {showAboutMenu && (
                <DropdownMenu
                  id="about-menu"
                  menuItems={[
                    { name: 'Our Story', url: 'https://bstrong.training/pages/about' },
                    { name: 'Meet the Team', url: 'https://bstrong.training/pages/team' }
                  ]}
                  onClose={() => setShowAboutMenu(false)}
                />
              )}
            </nav>
          </div>
        </div>
      </nav>
      <header
        id="page-header"
        className={classNames('flex flex-none items-center h-16 bg-white shadow-sm fixed top-0 right-0 left-0 z-30 ', {
          'md:pl-64': sidebarOpen
        })}>
        <div className="flex justify-between max-w-10xl mx-auto px-4 lg:px-8 w-full">
          <button
            type="button"
            className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-6 rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
            onClick={() => setSidebarOpen(!sidebarOpen)}>
            <MenuAlt1Icon className="inline-block w-5 h-5" />
          </button>
          <button
            onClick={logout}
            className="flex items-center space-x-3 px-3 font-medium rounded text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-50">
            <span className="flex-none flex items-center opacity-50">
              <LockOpenIcon className="inline-block w-5 h-5 text-red-600" />
            </span>
            <span className="py-2 flex-grow">Log out</span>
          </button>
        </div>
      </header>
      <main id="page-content" className="flex flex-auto flex-col max-w-full pt-16">
        <div className="max-w-10xl mx-auto p-4 lg:p-8 w-full">
          <Switch>
            <PrivateRoute exact path="/" component={OrderHistoryPage} />
            <PrivateRoute exact path="/order-preview/:orderNumber" component={OrderPreviewPage} />
            <PrivateRoute exact path="/certificates/" component={CertificatesPage} />
            <PrivateRoute exact path="/courses/" component={CoursesPage} />
            <PrivateRoute exact path="/course/:sku/quiz/:orderNumber/results" component={QuizResultsPage} />
            <PrivateRoute exact path="/course/:sku/quiz/:orderNumber" component={QuizPage} />
            <PrivateRoute exact path="/course/:sku/survey/:orderNumber/results" component={SurveyResultPage} />
            <PrivateRoute exact path="/course/:sku/survey/:orderNumber" component={SurveyPage} />
            <PrivateRoute exact path="/course/:sku" component={CoursePage} />
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default SidebarLayout;
