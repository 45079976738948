import axios, { AxiosResponse } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_ROOT_API; // Load base API url from appropriate environment config file
axios.defaults.withCredentials = true;
axios.defaults.validateStatus = (status: number) => status === 200; // Treat 200 as only valid status code

const errorHandler = (error: any) => {
  const statusCode = error.response?.status;

  // If unauthorized, redirect to login
  if (statusCode === 401) {
    window.location.href = '/login';
    return;
  }

  return Promise.reject({ ...error });
};

const successHandler = (response: AxiosResponse<any>) => {
  return response;
};

axios.interceptors.response.use(
  (response) => {
    return successHandler(response);
  },
  (error) => {
    return errorHandler(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return errorHandler(error);
  }
);

export const setAuthToken = (token: string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
  }
};

export const clearAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
  localStorage.removeItem('token');
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};
