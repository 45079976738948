import { CollectionIcon, CubeIcon, GlobeIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import axios from 'axios';
import OrderStatusIndicator from 'components/OrderStatusIndicator';
import PageHeader from 'components/SidebarLayout/PageHeader';
import TrackingLink from 'components/TrackingLink';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { OrderPreview } from 'types/orderPreview';
import { formatMoney } from 'utilities';

interface Params {
  orderNumber: string;
}

const OrderPreviewPage = () => {
  const { orderNumber } = useParams<Params>();
  const [orderPreview, setOrderPreview] = useState<OrderPreview>();

  useEffect(() => {
    axios.get<OrderPreview>(`order/preview/${orderNumber}`).then((response) => {
      const orderPreview = response.data;

      if (orderPreview) {
        setOrderPreview(orderPreview);
      }
    });
  }, [orderNumber]);

  return (
    <Fragment>
      {orderPreview && (
        <Fragment>
          <PageHeader
            Icon={ShoppingBagIcon}
            subTitle={
              orderPreview.trackingNumber && (
                <span>
                  Tracking: <TrackingLink trackingNumber={orderPreview.trackingNumber} iconSize={5} />
                </span>
              )
            }>
            <div className="flex justify-between items-center w-full">
              <span>Order #{orderNumber}</span> <OrderStatusIndicator status={orderPreview.status} />
            </div>
          </PageHeader>

          {(orderPreview.shippingDetails?.address || orderPreview.shippingDetails?.name) && (
            <div className="mb-12 text-gray-900">
              <div className="flex items-center mb-3">
                <GlobeIcon className="w-6 h-6 opacity-50 mr-2" />
                <h2 className="font-semibold text-xl text-gray-900">Shipping details</h2>
              </div>

              <div>
                <span className="font-medium">Name:</span> {orderPreview.shippingDetails.name}
              </div>
              <div>
                <span className="font-medium">Address:</span> {orderPreview.shippingDetails.address}
              </div>
              {orderPreview.shippingDetails.phone && (
                <div>
                  <span className="font-medium">Phone:</span> {orderPreview.shippingDetails.phone}
                </div>
              )}
            </div>
          )}

          <div className="flex items-center mb-3">
            <CubeIcon className="w-6 h-6 opacity-50 mr-2" />
            <h2 className="font-semibold text-xl text-gray-900">Products</h2>
          </div>
          <div className="space-y-2 mb-8">
            {orderPreview.items.map((item) => {
              return (
                <div
                  key={item.name}
                  className="grid grid-cols-1 sm:grid-cols-4 gap-1 sm:gap-1 justify-items-stretch items-center w-full rounded shadow-sm bg-white border-gray-300 border p-3 lg:p-4">
                  <h3 className="text-lg font-medium">{item.name}</h3>

                  <span className="sm:text-center text-gray-800">SKU: {item.sku}</span>

                  <span className="sm:text-center text-gray-800">Quantity: {item.quantity}</span>

                  <span className="font-semibold text-lg sm:text-right">{formatMoney(item.total)}</span>
                </div>
              );
            })}
            <div className="text-right text-lg pr-3 lg:pr-4">
              Total:{' '}
              <span className="font-semibold">
                {formatMoney(orderPreview.items.map((item) => item.total).reduce((a, b) => a + b, 0))}
              </span>
            </div>
          </div>
          <div>
            <div>
              <div>
                {orderPreview.items
                  .filter((item) => !item.sku.startsWith('EDU-'))
                  .map((item) =>
                    item.products.map((product, i) => {
                      return (
                        <Fragment key={`${product.productNoCombined}_${i}`}>
                          <div className="flex items-center mb-3 mt-12">
                            <CollectionIcon className="w-6 h-6 opacity-50 mr-2" />
                            <h3 className="font-semibold text-lg text-gray-900 ">
                              <span className="">Items for {product.productNoCombined}</span>
                            </h3>
                          </div>

                          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 lg:gap-4">
                            {product.parts.map((part) => {
                              return (
                                <div
                                  key={part.serial}
                                  className="flex flex-col rounded shadow-sm bg-white border-gray-300 border p-3 lg:p-3">
                                  <span className="font-medium mb-2">{part.name}</span>
                                  <span className="text-gray-800">SKU: {part.sku}</span>
                                  <span className="text-gray-800">Serial: {part.serial}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Fragment>
                      );
                    })
                  )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OrderPreviewPage;
