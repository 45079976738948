import { usePromiseTracker, trackPromise } from 'react-promise-tracker';

const usePageLoad = () => {
  const { promiseInProgress } = usePromiseTracker({ delay: 100 });

  return {
    trackRequest: trackPromise,
    isLoading: promiseInProgress
  };
};

export default usePageLoad;
