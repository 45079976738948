import React from 'react';
import classnames from 'classnames';
import { ExternalLinkIcon } from '@heroicons/react/outline';

interface Props {
  className?: string;
  trackingNumber: string;
  iconSize?: 4 | 5;
}

const TrackingLink = (props: Props) => {
  return (
    <a
      href={`https://www.google.com/search?q=${props.trackingNumber}`}
      className={classnames(
        'text-blue-600 hover:text-blue-400  active:text-blue-600, whitespace-nowrap',
        props.className
      )}
      target="blank">
      {props.trackingNumber}
      <ExternalLinkIcon
        className={classnames('inline-block ml-1 mb-1', {
          'h-4 w-4': !props.iconSize || props.iconSize === 4,
          'h-5 w-5': props.iconSize === 5
        })}
      />
    </a>
  );
};

export default TrackingLink;
