import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import {
  AcademicCapIcon,
  CalendarIcon,
  DownloadIcon,
  ExclamationIcon,
  VideoCameraIcon,
  XIcon
} from '@heroicons/react/outline';
import PageHeader from 'components/SidebarLayout/PageHeader';
import { CertificateListItem } from 'types/certificate';
import { formatDate } from 'utilities/datetime';
import Button from 'components/Button';
import { openNewTab } from 'utilities';
import { getAuthToken } from 'utilities/http';
import usePageLoad from 'components/PageLoad/usePageLoad';
import PageLoadingArea from 'components/PageLoad';

const CertificatesPage = () => {
  const [certificates, setCertificates] = useState<CertificateListItem[] | undefined>(undefined);
  const [certificateToPreview, setCertificateToPreview] = useState<CertificateListItem>();
  const { trackRequest } = usePageLoad();

  useEffect(() => {
    trackRequest(
      axios.get<CertificateListItem[]>(`certificate`).then((response) => {
        const certificates = response.data;

        if (certificates) {
          setCertificates(certificates);
        }
      })
    );
  }, [trackRequest]);

  return (
    <Fragment>
      <PageHeader Icon={AcademicCapIcon}>Certifications</PageHeader>
      <PageLoadingArea>
        {certificates && certificates.length > 0 && (
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 lg:gap-6">
            {certificates.map((certificate, i) => {
              return (
                <button
                  key={i}
                  className="border-gray-300 hover:border-gray-400 border rounded shadow-sm bg-white overflow-hidden p-3 lg:p-4"
                  onClick={() => setCertificateToPreview(certificate)}>
                  <h3 className="text-lg font-medium">
                    {certificate.name} ({formatDate(certificate.date)})
                  </h3>
                </button>
              );
            })}
          </div>
        )}
        {certificates && certificates.length === 0 && (
          <div className="p-4 md:p-5 rounded text-orange-700 bg-orange-100">
            <div className="flex items-center">
              <ExclamationIcon className="inline-block w-5 h-5 mr-3 flex-none text-orange-500" />
              <h3 className="flex-grow">
                You have no certifications in your account. You can get certified on B Strong BFR by ordering a course
                product in our{' '}
                <a target="blank" href="https://bstrong.training/" className="underline">
                  shop
                </a>
                .
              </h3>
            </div>
          </div>
        )}
        {certificateToPreview && (
          <div
            className="z-90 fixed inset-0 overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-75 p-4 lg:p-8 flex justify-center items-center"
            tabIndex={-1}
            role="dialog">
            <div
              className="flex flex-col rounded shadow-sm bg-white overflow-hidden w-full md:w-2/3 lg:w-11/12 xl:w-9/12 2xl:w-7/12"
              role="document">
              <div className="py-4 px-5 lg:px-6 w-full flex justify-between items-center">
                <h3 className="text-lg font-medium">{certificateToPreview.name}</h3>
                <div className="-my-4">
                  <button
                    onClick={() => setCertificateToPreview(undefined)}
                    type="button"
                    title="Close"
                    className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-transparent text-gray-600 hover:text-gray-400 focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:text-gray-600">
                    <XIcon className="inline-block w-6 h-6 -mx-1" />
                  </button>
                </div>
              </div>
              <div className="">
                <div className="font-semibold inline-flex ml-4 mb-3 px-4 py-2 leading-4 items-center space-x-2  rounded-full text-blue-700 bg-blue-200">
                  <CalendarIcon className="inline-block w-6 h-6 opacity-75ss" />

                  <span>{formatDate(certificateToPreview.date)}</span>
                </div>
              </div>
              <div className="p-5 lg:p-6 flex-grow w-full bg-blue-50 bg-opacity-60 border-t border-b flex justify-center text-center">
                <div>
                  {certificateToPreview.instructorPhotoUrl && (
                    <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
                      <img
                        src={certificateToPreview.instructorPhotoUrl}
                        alt="Instructor"
                        className="inline-block w-28 h-28 rounded-full"
                      />
                    </span>
                  )}
                  <h4 className="text-xl font-semibold mb-1">{certificateToPreview.instructor}</h4>
                  <p className="text-gray-600 font-medium">Your instructor</p>
                </div>
              </div>
              <div className="py-4 px-5 lg:px-6 w-full flex justify-between flex-wrap flex-col lg:flex-row">
                {certificateToPreview.zoomUrl && (
                  <Button
                    type="button"
                    schema="primary"
                    className="inline-flex justify-center items-center space-x-2 mt-2"
                    onClick={() => openNewTab(certificateToPreview.zoomUrl)}>
                    <VideoCameraIcon className="inline-block w-5 h-5" />
                    <span>Zoom link</span>
                  </Button>
                )}
                {certificateToPreview.recordingUrl && (
                  <Button
                    type="button"
                    schema="primary"
                    className="inline-flex justify-center items-center space-x-2 mt-2"
                    onClick={() => openNewTab(certificateToPreview.recordingUrl)}>
                    <VideoCameraIcon className="inline-block w-5 h-5" />
                    <span>Recording link</span>
                  </Button>
                )}
                <Button
                  type="button"
                  className="inline-flex justify-center items-center space-x-2 mt-2"
                  onClick={() =>
                    openNewTab(
                      `${process.env.REACT_APP_ROOT_API}/certificate/download/${
                        certificateToPreview.orderNumber
                      }/${getAuthToken()}`
                    )
                  }>
                  <DownloadIcon className="inline-block w-5 h-5" />
                  <span>Certificate download</span>
                </Button>
                {certificateToPreview.manualUrl && (
                  <Button
                    type="button"
                    className="inline-flex justify-center items-center space-x-2 mt-2"
                    onClick={() => openNewTab(certificateToPreview.manualUrl)}>
                    <DownloadIcon className="inline-block w-5 h-5" />
                    <span>Course manual download</span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </PageLoadingArea>
    </Fragment>
  );
};

export default CertificatesPage;
