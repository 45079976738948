import { Switch, Route } from 'react-router-dom';
import 'styles/tailkit.build.css';
import 'styles/main.css';
import 'utilities/http';
import PrivateRoute from 'components/PrivateRoute';
import LoginPage from 'pages/LoginPage';
import SidebarLayout from 'components/SidebarLayout';
import ToastProvider from 'components/ToastProvider';

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/login" component={LoginPage} />

        {/* All sub routes for sidebar layout are defined in SidebarLayout component */}
        <PrivateRoute path="/" component={SidebarLayout} />

        {/* <Route path="/404" component={NotFoundPage} /> */}
        {/* No routes matched, redirect to not found page */}
        {/* <Redirect to="/404" /> */}
      </Switch>
      <ToastProvider />
    </>
  );
};

export default App;
