import React, { useContext, useReducer } from 'react';
import { reducer } from './reducer';
import { Action } from './action';
import AppState from 'types/appState';

export interface Store {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

const AppContext = React.createContext<Store>({} as Store);

export const useAppState = () => useContext(AppContext);

export const AppStateProvider = ({ initialState, children }: React.PropsWithChildren<{ initialState: AppState }>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
