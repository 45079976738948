export const isProduction = process.env.NODE_ENV === 'production';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const isMobile = window.innerWidth < 640;

export const openNewTab = (url: string) => window.open(url, '_blank');

export const formatMoney = (amount: number = 0) => {
  let result = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

  if (result.endsWith('.00')) result = result.replace('.00', '.-');

  return result;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
