import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { AppStateProvider } from 'state';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <AppStateProvider initialState={{ authenticated: false }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
