import { ClipboardListIcon, DownloadIcon } from '@heroicons/react/outline';
import PageHeader from 'components/SidebarLayout/PageHeader';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { openNewTab } from 'utilities';
import { getAuthToken } from 'utilities/http';

interface Params {
  orderNumber: string;
}

const SurveyResultPage = () => {
  const { orderNumber } = useParams<Params>();

  return (
    <Fragment>
      <PageHeader Icon={ClipboardListIcon}>Course Survey Completed</PageHeader>

      <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden mb-8 border">
        <div className="p-5 lg:p-6 flex-grow w-full border-l-4 border-green-300 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between sm:space-x-2">
          <div className="">
            <h3 className="text-lg font-semibold mb-1">Awesome, you have passed the quiz and finished the survey!</h3>

            <p className="text-gray-500">You can now download your certificate!</p>
          </div>
          <div className="flex-none">
            <button
              type="submit"
              className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-4 py-3 leading-6 rounded border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200"
              onClick={() =>
                openNewTab(`${process.env.REACT_APP_ROOT_API}/certificate/download/${orderNumber}/${getAuthToken()}`)
              }>
              <span className="">Download certificate</span>
              <DownloadIcon className="inline-block w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SurveyResultPage;
