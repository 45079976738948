import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import Button from 'components/Button';
import logoLight from 'media/logo-light.png';
import logoGoogle from 'media/google.svg';
// import logoFacebook from 'media/facebook.svg';
import bgVideo from 'media/bg-video.mp4';
import { useAppState } from 'state';
import './styles.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import LoginCredentials from 'types/loginCredentials';
import axios from 'axios';
import UserProfile from 'types/userProfile';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getAuthToken, setAuthToken } from 'utilities/http';
import Loader from 'components/Loader';
import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login-typed';

const LoginPage = () => {
  const [signInMode, setSignInMode] = useState<SignInMode>('email');
  const [showEmailVerification, setShowEmailVerification] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loginToken, setLoginToken] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch, state } = useAppState();
  const { push } = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginCredentials>();
  const location = useLocation<{ from: string }>();
  const returnUrl = location.state?.from || '/';

  type SignInMode = 'email' | 'social' | 'password';

  const invalidCredentialsErrorMessage = 'Invalid email or password';
  const loginErrorMessage = 'Something went wrong with social login';
  const socialLoginErrorMessage = 'Something went wrong with social login, please try again';

  const SignInModeTab: React.FC<{ mode: SignInMode }> = ({ mode, children }) => {
    const isActive = signInMode === mode;

    return (
      <button
        className={classNames(
          'px-3 md:px-5 font-medium -mb-px flex items-center space-x-2 py-4 border-b-2 flex-grow justify-center',
          {
            'text-gray-500 border-transparent hover:text-blue-500 active:text-gray-500': !isActive,
            'text-blue-500 border-blue-500': isActive
          }
        )}
        onClick={() => {
          setErrorMessage(undefined);
          setSignInMode(mode);
        }}>
        {children}
      </button>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setProfileAndRedirect = (profile: UserProfile) => {
    setAuthToken(profile.token);
    dispatch({ type: 'LOGIN', payload: profile });
    push(returnUrl);
  };

  // If token exists check for acrive session
  useEffect(() => {
    const authToken = getAuthToken();

    if (!state.userProfile && !state.authenticated && authToken) {
      setAuthToken(authToken);
      setLoading(true);

      axios
        .get<UserProfile>(`account/checkAuth/${authToken}`)
        .then((response) => {
          setLoading(false);

          const profile = response.data;

          if (profile) {
            dispatch({ type: 'LOGIN', payload: profile });
            // TODO: maybe generate new token in api and set it here

            push(returnUrl);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start checking for login approval from api when login token is set
  useEffect(() => {
    if (loginToken) {
      const interval = setInterval(() => {
        axios.get<UserProfile>(`account/login/check/${loginToken}`).then((response) => {
          const profile = response.data;

          if (profile) {
            clearInterval(interval);
            setLoginToken(undefined);
            setProfileAndRedirect(profile);
          }
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [loginToken, dispatch, push, returnUrl, setProfileAndRedirect]);

  const onSubmit: SubmitHandler<LoginCredentials> = (data: LoginCredentials) => {
    setErrorMessage(undefined);

    if (signInMode === 'password') {
      axios
        .post<UserProfile>('account/login', data)
        .then((response) => {
          const profile = response.data;

          if (profile) {
            setProfileAndRedirect(profile);
          }
        })
        .catch(() => {
          setErrorMessage(invalidCredentialsErrorMessage);
        });
    } else if (signInMode === 'email') {
      setShowEmailVerification(true);
      axios
        .post<string>('account/login/byEmail', data)
        .then((response) => {
          const loginToken = response.data;

          if (loginToken) {
            setLoginToken(loginToken);
          }
        })
        .catch(() => {
          setErrorMessage(loginErrorMessage);
        });
    }
  };

  const googleLogin = (response: any) => {
    setErrorMessage(undefined);

    axios
      .post<UserProfile>(`account/externalLogin/google/${response?.tokenId}`)
      .then((response) => {
        const profile = response.data;

        if (profile) {
          setProfileAndRedirect(profile);
        }
      })
      .catch(() => {
        setErrorMessage(socialLoginErrorMessage);
      });
  };

  // const facebookLogin = (response: any) => {
  //   setErrorMessage(undefined);

  //   axios
  //     .post<UserProfile>(`account/externalLogin/facebook/${response?.accessToken}`)
  //     .then((response) => {
  //       const profile = response.data;

  //       if (profile) {
  //         setProfileAndRedirect(profile);
  //       }
  //     })
  //     .catch(() => {
  //       setErrorMessage(socialLoginErrorMessage);
  //     });
  // };

  // Redirect when user is already authenticated
  if (state.authenticated) {
    return <Redirect to={returnUrl} />;
  }

  return (
    <Fragment>
      <div id="page-container" className="flex flex-col mx-auto w-full min-h-screen bg-gray-100">
        <video className="bg-video-image bg-cover absolute w-full" loop autoPlay muted playsInline src={bgVideo} />
        <main id="page-content" className="flex flex-auto flex-col max-w-full">
          <div className="min-h-screen flex justify-center relative overflow-hidden max-w-10xl mx-auto p-4 lg:p-8 w-full">
            <div className="py-6 lg:py-0 w-full md:w-8/12 lg:w-6/12 xl:w-4/12 relative">
              <div className="mb-8 text-center">
                <img src={logoLight} className="logo-shadow inline-block h-12 sm:h-24" alt="Logo" />
                <h1 className="title-shadow mt-6 text-xl sm:text-2xl text-white">
                  Welcome, please sign in to continue!
                </h1>
              </div>

              <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden bg-opacity-95">
                {!showEmailVerification && !loading && (
                  <nav className="flex items-center border-b border-gray-200 bg-white">
                    <SignInModeTab mode="email">Email only</SignInModeTab>
                    <SignInModeTab mode="social">Social media</SignInModeTab>
                    <SignInModeTab mode="password">Email and password</SignInModeTab>
                  </nav>
                )}
                <div className="p-5 lg:p-6 flex-grow w-full">
                  <div className="sm:p-5 lg:px-10 lg:py-8">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Fragment>
                        {errorMessage && errorMessage.length > 0 && (
                          <div className="p-4 md:p-5 rounded mb-4 -mt-8 text-red-700 bg-red-100">
                            <h3 className="font-semibold">{errorMessage}</h3>
                          </div>
                        )}
                        {signInMode === 'email' && !showEmailVerification && (
                          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div className="space-y-1">
                              <label htmlFor="email" className="font-medium">
                                Email
                              </label>
                              <input
                                className={classNames(
                                  'block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'
                                )}
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                {...register('email', { required: true })}
                              />
                              {errors.email && <p className="text-sm text-red-600">This field is required</p>}
                            </div>
                            <div>
                              <Button type="submit" schema="primary" className="w-full">
                                Sign In
                              </Button>
                            </div>
                          </form>
                        )}
                        {signInMode === 'email' && showEmailVerification && (
                          <div className="p-4 md:p-5 rounded text-blue-700 bg-blue-100 -m-4 md:-mx-14 lg:-mx-16 md:-my-14">
                            <p>
                              Thank you for your request to access GoBStrong platform. To log in, we need to verify your
                              email address first. Instructions have been sent to your email.{' '}
                              <span className="font-medium">PLEASE KEEP THIS BROWSER WINDOW OPEN</span>, you will be
                              redirected after successful email verification.
                            </p>
                          </div>
                        )}
                        {signInMode === 'social' && (
                          <div className="space-y-6">
                            <GoogleLogin
                              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
                              buttonText="Continue with Google"
                              onSuccess={googleLogin}
                              onFailure={(error) => {
                                console.log('Google auth error: ', error);
                              }}
                              render={(renderProps) => (
                                <Button
                                  type="button"
                                  className="w-full space-x-2"
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}>
                                  <img src={logoGoogle} className="inline-block h-7 w-7" alt="Google" />
                                  <span>Continue with Google</span>
                                </Button>
                              )}
                            />
                            {/* <FacebookLogin
                              appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
                              callback={facebookLogin}
                              onFailure={(error) => {
                                console.log('Facebook auth error: ', error);
                              }}
                              render={(renderProps) => (
                                <Button
                                  type="button"
                                  className="w-full space-x-2"
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.isDisabled}>
                                  <img src={logoFacebook} className="inline-block h-7 w-7" alt="Facebook" />
                                  <span>Continue with Facebook</span>
                                </Button>
                              )}
                            /> */}
                          </div>
                        )}
                        {signInMode === 'password' && (
                          <Fragment>
                            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                              <div className="space-y-1">
                                <label htmlFor="email" className="font-medium">
                                  Email
                                </label>
                                <input
                                  className="block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                  type="email"
                                  id="email"
                                  placeholder="Enter your email"
                                  {...register('email', { required: true })}
                                />
                                {errors.email && <p className="text-sm text-red-600">This field is required</p>}
                              </div>
                              <div className="space-y-1">
                                <label htmlFor="password" className="font-medium">
                                  Password
                                </label>
                                <input
                                  className="block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                  type="password"
                                  id="password"
                                  placeholder="Enter your password"
                                  {...register('password', { required: true })}
                                />
                                {errors.password && <p className="text-sm text-red-600">This field is required</p>}
                              </div>
                              <div>
                                <Button type="submit" schema="primary" className="w-full">
                                  Sign In
                                </Button>

                                {/* <a href="/" className="mt-4 block text-blue-600 hover:text-blue-400">
                                  Forgot Password?
                                </a> */}
                              </div>
                            </form>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
                {/* {signInMode === 'password' && (
                  <div className="py-4 px-5 lg:px-6 w-full text-sm text-center bg-gray-50">
                    Don’t have an account yet?
                    <a href="/" className="font-medium text-blue-600 hover:text-blue-400 ml-2">
                      Sign up!
                    </a>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default LoginPage;
