import { ClipboardListIcon } from '@heroicons/react/outline';
import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/Button';
import PageHeader from 'components/SidebarLayout/PageHeader';
import { useToast } from 'components/ToastProvider';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CourseSurveyAnswer, CourseSurveyGroup, CourseSurveyQuestion } from 'types/course';
import { scrollToTop } from 'utilities';

interface Params {
  orderNumber: string;
  sku: string;
}

const SurveyPage = () => {
  const [surveyGroups, setSurveyGroups] = useState<CourseSurveyGroup[]>();
  const [activeSurveyGroup, setActiveSurveyGroup] = useState<CourseSurveyGroup>();
  const { push } = useHistory();
  const { orderNumber, sku } = useParams<Params>();
  const { errorMessage } = useToast();

  useEffect(() => {
    axios.get<CourseSurveyGroup[]>(`course/survey/${sku}`).then((response) => {
      const groups = response.data;

      if (groups && groups.length > 0) {
        setSurveyGroups(groups);
        setActiveSurveyGroup(groups[0]);
      }
    });
  }, [sku]);

  // Scroll to top when group is changed
  useEffect(() => {
    if (activeSurveyGroup) {
      scrollToTop();
    }
  }, [activeSurveyGroup]);

  if (!activeSurveyGroup || !surveyGroups) return <Fragment />;

  const groupItems = activeSurveyGroup.subGroups
    .map((subGroup) => {
      return { id: subGroup.id, orderNo: subGroup.orderNo, isSubGroup: true };
    })
    .concat(
      activeSurveyGroup.questions.map((question) => {
        return { id: question.id, orderNo: question.orderNo || 0, isSubGroup: false };
      })
    )
    .sort((a, b) => {
      return a.orderNo - b.orderNo;
    });

  //  answerText is for explanation for some answers or for a text only answer when answerId is undefined
  const updateQuestionAnswer = (questionId: string, answerId?: string, answerText?: string) => {
    const updateAnswerInternal = (questions: CourseSurveyQuestion[]) => {
      const updatedQuestions = questions.map((question) => {
        if (question.id === questionId) {
          // Text only answer or explanation for an answer
          if (answerText) {
            question.answerText = answerText;
          } else if (answerId) {
            question.answers = question.answers.map((answer) => {
              if (answer.id === answerId) {
                answer.selected = true;
              } else {
                answer.selected = false;
              }

              return answer;
            });
          }
        }

        return question;
      });

      return updatedQuestions;
    };

    const updatedSurveyGroups = surveyGroups.map((surveyGroup) => {
      // Go over top level questions
      surveyGroup.questions = updateAnswerInternal(surveyGroup.questions);

      // Go over questions inside sub groups
      surveyGroup.subGroups = surveyGroup.subGroups.map((subGroup) => {
        subGroup.questions = updateAnswerInternal(subGroup.questions);

        return subGroup;
      });

      return surveyGroup;
    });

    setSurveyGroups(updatedSurveyGroups);
  };

  const isSurveyAnswered = () => {
    return surveyGroups.every((surveyGroup) => iSurveyGroupAnswered(surveyGroup));
  };

  const iSurveyGroupAnswered = (surveyGroup: CourseSurveyGroup) => {
    const isSurveyQuestionAnswered = (surveyQuestion: CourseSurveyQuestion) => {
      // Text only answer
      if (surveyQuestion.answers.length === 0 && surveyQuestion.answerText && surveyQuestion.answerText.length > 0)
        return true;

      return surveyQuestion.answers.some((answer) => answer.selected);
    };

    const notAnsweredQuestions = surveyGroup.questions.filter((q) => !isSurveyQuestionAnswered(q));

    const notAnsweredSubGroupQuestions = surveyGroup.subGroups
      .flatMap((subGroup) => subGroup.questions)
      .filter((q) => !isSurveyQuestionAnswered(q));

    return notAnsweredQuestions.length === 0 && notAnsweredSubGroupQuestions.length === 0;
  };

  const saveAnswers = () => {
    if (!isSurveyAnswered()) {
      errorMessage('Please answer all course survey questions!');
      return;
    }

    const allQuestions = surveyGroups.flatMap((group) => {
      return group.questions.concat(group.subGroups.flatMap((subGroup) => subGroup.questions));
    });

    const answers = allQuestions
      .map((question) => {
        const result = {
          surveyQuestionId: question.id,
          answerText: question.answerText
        } as CourseSurveyAnswer;

        const answer = question.answers.find((answer) => answer.selected);

        if (answer) {
          result.surveyQuestionAnswerId = answer.id;
          result.value = answer.value;
        }

        return result;
      })
      .filter((answer) => answer.answerText || answer.surveyQuestionAnswerId);

    axios.post<CourseSurveyAnswer[]>('course/survey/answer', answers).then(() => {
      push(`/course/${sku}/survey/${orderNumber}/results`);
    });
  };

  return (
    <Fragment>
      <PageHeader Icon={ClipboardListIcon}>
        Course Survey, Group {activeSurveyGroup.orderNo}/{surveyGroups.length}
      </PageHeader>
      <h3 className="font-bold underlidne sm:text-2xl mb-4">{activeSurveyGroup.text}</h3>
      <div className="space-y-2">
        {groupItems.map((item) => {
          const isSubGroup = item.isSubGroup;

          if (isSubGroup) {
            const subGroup = activeSurveyGroup.subGroups.find((sg) => sg.id === item.id)!;

            return (
              <div className="py-4 px-4 xl:w-3/4 2xl:w-3/5 rounded border bg-white" key={item.id}>
                <h4 className="font-medium sm:text-lg mb-2">
                  {subGroup.orderNo}. {subGroup.text}
                </h4>

                <div className="space-y-2 mx-4 mt-4">
                  {subGroup.questions.map((question, idx) => {
                    question.orderNo = idx += 1;
                    return (
                      <SurveyQuestionView
                        key={question.id}
                        parentOrderNo={subGroup.orderNo}
                        surveyQuestion={question}
                        onAnswer={updateQuestionAnswer}
                      />
                    );
                  })}
                </div>
              </div>
            );
          } else {
            const question = activeSurveyGroup.questions.find((sg) => sg.id === item.id)!;

            return <SurveyQuestionView key={item.id} surveyQuestion={question} onAnswer={updateQuestionAnswer} />;
          }
        })}
      </div>

      <div className="flex sm:space-x-2 space-y-2 sm:space-y-0 mt-8 flex-col sm:flex-row">
        {activeSurveyGroup.orderNo < surveyGroups.length && (
          <Button
            type="button"
            schema="primary"
            onClick={() => {
              if (!iSurveyGroupAnswered(activeSurveyGroup)) {
                errorMessage('Please answer all course survey questions!');
                return;
              }

              setActiveSurveyGroup(surveyGroups[activeSurveyGroup.orderNo]);
            }}>
            Next survey group
          </Button>
        )}
        {activeSurveyGroup.orderNo === surveyGroups.length && (
          <Button type="button" schema="primary" onClick={saveAnswers}>
            Save answers
          </Button>
        )}

        {activeSurveyGroup.orderNo > 1 && (
          <Button
            type="button"
            schema="simple"
            onClick={() => setActiveSurveyGroup(surveyGroups[activeSurveyGroup.orderNo - 2])}>
            Previous survey qroup
          </Button>
        )}
      </div>
    </Fragment>
  );
};

const SurveyQuestionView = (props: {
  surveyQuestion: CourseSurveyQuestion;
  parentOrderNo?: number;
  onAnswer: (questionId: string, answerId?: string, answerText?: string) => void;
}) => {
  const isSubQuestion = props.parentOrderNo !== undefined;
  const orderNumber = isSubQuestion
    ? `${props.parentOrderNo}.${props.surveyQuestion.orderNo}`
    : `${props.surveyQuestion.orderNo}.`;

  return (
    <div
      className={classNames(
        'space-y-4 rounded border bg-white p-4',
        !isSubQuestion && 'xl:w-3/4 2xl:w-3/5 ',
        isSubQuestion && 'border-gray-300 w-full'
      )}>
      <div className="font-medium sm:text-lg">
        {`${orderNumber} ${props.surveyQuestion.text}`} <br />
      </div>
      <div className="space-y-2 pl-2">
        {props.surveyQuestion.answers.length > 0 &&
          props.surveyQuestion.answers.map((answer) => {
            return (
              <Fragment key={`question-${props.surveyQuestion.id}-answer-${answer.id}`}>
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name={`question-answer-${answer.id}`}
                    checked={answer.selected}
                    value={answer.selected?.toString()}
                    onChange={() => props.onAnswer(props.surveyQuestion.id, answer.id)}
                    className={classNames(
                      'border border-blue-500 h-4 w-4 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'
                    )}
                  />
                  <span className="ml-2">
                    {answer.letter && <span className="font-medium">{answer.letter}. </span>}
                    {answer.text} <br />
                  </span>
                </label>

                {/* Additional textarea when answer could be explained  */}
                {answer.value === 0 && (
                  <textarea
                    className="w-full block border border-gray-300 bg-gray-50 bg-opacity-30 rounded px-3 py-2 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                    rows={2}
                    maxLength={1000}
                    defaultValue={props.surveyQuestion.answerText}
                    onChange={(e) => props.onAnswer(props.surveyQuestion.id, answer.id, e.target.value)}
                  />
                )}
              </Fragment>
            );
          })}

        {/* Textarea only answer if question has no individual answers */}
        {props.surveyQuestion.answers.length === 0 && (
          <div>
            <textarea
              className="w-full block border border-gray-300 bg-gray-50 bg-opacity-30 rounded px-3 py-2 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              rows={4}
              maxLength={1000}
              defaultValue={props.surveyQuestion.answerText}
              onChange={(e) => props.onAnswer(props.surveyQuestion.id, undefined, e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyPage;
