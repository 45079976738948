export default interface Order {
  orderNumber: number;
  createdAt: Date;
  status: OrderStatus;
  paid: string;
  trackingNumber: string;
  customer: string;
  email: string;
  shipment: string;
  description: string;
  discount: string;
  total?: number;
  id: string; // guid test
  isDistributorOrder: boolean;
  financialStatus: string;
}

export enum OrderStatus {
  new = 0,
  processing = 1,
  prepared = 2,
  shipped = 3,
  delivered = 4
}
