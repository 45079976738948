import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { ExclamationIcon, FilmIcon } from '@heroicons/react/outline';
import PageHeader from 'components/SidebarLayout/PageHeader';
import { formatDate } from 'utilities/datetime';
import { useHistory, useParams } from 'react-router-dom';
import { CourseListItem } from 'types/course';
import PageLoadingArea from 'components/PageLoad';
import usePageLoad from 'components/PageLoad/usePageLoad';

interface Params {
  orderNumber?: string;
}

const CoursesPage = () => {
  const [courses, setCourses] = useState<CourseListItem[] | undefined>(undefined);
  const { orderNumber } = useParams<Params>();
  const { push } = useHistory();
  const { trackRequest } = usePageLoad();

  useEffect(() => {
    trackRequest(
      axios.get<CourseListItem[]>(`course`).then((response) => {
        const courses = response.data;

        if (courses) {
          setCourses(courses);
        }
      })
    );
  }, [orderNumber, trackRequest]);

  return (
    <Fragment>
      <PageHeader Icon={FilmIcon}>Courses</PageHeader>
      <PageLoadingArea>
        {courses && courses.length > 0 && (
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 lg:gap-6">
            {courses.map((course, i) => {
              return (
                <button
                  key={i}
                  className="border-gray-300 hover:border-gray-400 border rounded shadow-sm bg-white overflow-hidden p-3 lg:p-4"
                  onClick={() => push(`/course/${course.sku}`)}>
                  <h3 className="text-lg font-medium">
                    {course.name} ({formatDate(course.date)})
                  </h3>
                </button>
              );
            })}
          </div>
        )}
        {courses && courses.length === 0 && (
          <div className="p-4 md:p-5 rounded text-orange-700 bg-orange-100">
            <div className="flex items-center">
              <ExclamationIcon className="inline-block w-5 h-5 mr-3 flex-none text-orange-500" />
              <h3 className="flex-grow">
                You have not yet bought a course. You can get certified on B Strong BFR by ordering a course product in
                our{' '}
                <a
                  target="blank"
                  href="https://bstrong.training/products/bfr-theory-and-application/"
                  className="underline">
                  shop
                </a>
                .
              </h3>
            </div>
          </div>
        )}
      </PageLoadingArea>
    </Fragment>
  );
};

export default CoursesPage;
