import AppState from 'types/appState';
import { Action } from './action';

export const reducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, authenticated: true, userProfile: action.payload };
    case 'LOG_OUT':
      return { ...state, authenticated: false, userProfile: undefined };

    default:
      throw new Error(`Action not found`);
  }
};
