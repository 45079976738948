import {
  ArrowRightIcon,
  DownloadIcon,
  EyeIcon,
  InformationCircleIcon,
  VideoCameraIcon
} from '@heroicons/react/outline';
import PageHeader from 'components/SidebarLayout/PageHeader';
import VideoPlayer from 'components/VideoPlayer';
import React, { Fragment, useEffect, useState } from 'react';
import courseSampleVideo from 'media/course_video_sample.mp4';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/Button';
import { isDevelopment, openNewTab } from 'utilities';
import axios from 'axios';
import { CourseViewModel } from 'types/course';
import { getAuthToken } from 'utilities/http';

interface Params {
  sku: string;
}
const CoursePage = () => {
  const { push } = useHistory();
  const [videoPlaybackEnded, setVideoPlaybackEnded] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [course, setCourse] = useState<CourseViewModel | undefined>(undefined);
  const { sku } = useParams<Params>();
  const [timestamp, setTimestamp] = useState(0);
  const progresKey = `video-progres-${sku}`;
  // const videoPath = isDevelopment ? courseSampleVideo : `/static/media/course/${sku}.mp4`;
  const videoPath = `/static/media/course/${sku}.mp4`;

  useEffect(() => {
    axios.get<CourseViewModel>(`course/${sku}`).then((response) => {
      const course = response.data;

      if (course) {
        setCourse(course);

        const previousProgres = Number(localStorage.getItem(progresKey));

        if (!isNaN(previousProgres)) {
          setTimestamp(previousProgres);
        }

        if (course.isQuizPassed) {
          setShowVideo(false);
        }
      }
    });
  }, [progresKey, sku]);

  return (
    <Fragment>
      {course && (
        <Fragment>
          <PageHeader Icon={VideoCameraIcon}>{course.name}</PageHeader>

          {course.isQuizPassed && course.isSurveyAnswered && (
            <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden mb-8 border">
              <div className="p-5 lg:p-6 flex-grow w-full border-l-4 border-green-300 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between sm:space-x-2">
                <div className="">
                  <h3 className="text-lg font-semibold mb-1">
                    Congrats: You have finished the course and passed the quiz!
                  </h3>
                  <p className="text-gray-500">You can now download your certificate.</p>
                </div>
                <button
                  type="submit"
                  className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-4 py-3 leading-6 rounded border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200"
                  onClick={() =>
                    openNewTab(
                      `${process.env.REACT_APP_ROOT_API}/certificate/download/${course.orderNumber}/${getAuthToken()}`
                    )
                  }>
                  <DownloadIcon className="inline-block w-5 h-5" />
                  <span className="">Download certificate</span>
                </button>
              </div>
            </div>
          )}

          {course.isQuizPassed && !course.isSurveyAnswered && (
            <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden mb-8 border">
              <div className="p-5 lg:p-6 flex-grow w-full border-l-4 border-orange-300 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between sm:space-x-2">
                <div className="">
                  <h3 className="text-lg font-semibold mb-1">
                    You have passed the quiz but have not yet finished the course survey!
                  </h3>
                  <p className="text-gray-500">
                    Please fill out the survey and then you will be able to download the course certificate!
                  </p>
                </div>
                <div className="flex-none">
                  <button
                    type="submit"
                    className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-4 py-3 leading-6 rounded border-orange-200 bg-orange-200 text-orange-700 hover:text-orange-700 hover:bg-orange-300 hover:border-orange-300 focus:ring focus:ring-orange-500 focus:ring-opacity-50 active:bg-orange-200"
                    onClick={() => push(`/course/${sku}/survey/${course.orderNumber}`)}>
                    <ArrowRightIcon className="inline-block w-5 h-5" />
                    <span className="">Fill out the survey</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {showVideo && (
            <div className="flex items-top flex-col-reverse lg:flex-row 2xl:mx-24 lg:space-x-4">
              <div className="w-full lg:w-2/3">
                <VideoPlayer
                  src={videoPath}
                  timestamp={timestamp}
                  blockSeekForward={!course.isQuizPassed}
                  onPlaybackEnded={() => {
                    setVideoPlaybackEnded(true);
                    document.getElementById('course-watched-message')?.scrollIntoView({ behavior: 'smooth' });
                  }}
                  onPlaybackProgress={(timestamp: number) => {
                    localStorage.setItem(progresKey, timestamp.toString());
                  }}
                  onPlaybackPaused={(timestamp: number) => {
                    localStorage.setItem(progresKey, timestamp.toString());
                  }}
                />
              </div>
              <div className="mb-4 lg:mb-0 p-4 md:p-5 rounded text-blue-700 bg-blue-100 w-full lg:w-1/3 h-fit-content border border-blue-400">
                <div className="flex items-center mb-2">
                  <InformationCircleIcon className="inline-block w-6 h-6 mr-3 flex-none text-blue-500" />
                  <h3 className="font-medium text-lg">HOW TO TAKE YOUR COURSE</h3>
                </div>
                {course.hasQuiz && (
                  <div className="">
                    <ol className="block my-2 list-decimal pl-6">
                      <li>Download the course manual and review it.</li>
                      <li>Watch the video all the way to the end, you cannot continue until you watch the full video.</li>
                      
                      <li>
                        Answer the test questions to get a passing grade. If you need to retake the test, a video review
                        will be available for any incorrect answers.
                      </li>
                      <li>Once past the test, answer all survey questions.</li>
                      
                      <li>Download your certificate, Congratulations!</li>
                    </ol>
                    <p>
                      NOTE: You can re-download your certificate at any time and review the video as long as it is
                      available.
                    </p>
                  </div>
                )}
                {!course.hasQuiz && (
                  <div className="">
                    <ol className="block my-2 list-decimal pl-6">
                      <li>Download the course manual and review it.</li>
                      <li>Watch the video all the way to the end, you cannot continue until you watch the full video.</li>
                      <li>Download your certificate, Congratulations!</li>
                    </ol>
                    <p>
                      NOTE: You can re-download your certificate at any time and review the video as long as it is
                      available.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {videoPlaybackEnded && !course.isQuizPassed && (
            <div
              id="course-watched-message"
              className="flex flex-col rounded shadow-sm bg-white overflow-hidden mt-8 border">
              <div className="p-5 lg:p-6 flex-grow w-full border-l-4 border-green-300 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between sm:space-x-2">
                <div className="">
                  <h3 className="text-lg font-semibold mb-1">Congrats!</h3>
                  <p className="text-gray-500">
                    You have finished watching the course video, you can now take the quiz and survey to get your
                    certification
                  </p>
                </div>
                <div className="flex-none">
                  {course.hasQuiz && (
                    <button type="submit" className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-4 py-3 leading-6 rounded border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200"
                      onClick={() => push(`/course/${sku}/quiz/${course.orderNumber}`)}>
                      <span className="">Go to Quiz</span>
                      <ArrowRightIcon className="inline-block w-5 h-5" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-2 mt-6 sm:justify-center">
            {course.isQuizPassed && !showVideo && (
              <Button
                type="button"
                className="inline-flex justify-center items-center space-x-2 mt-2"
                onClick={() => {
                  setTimestamp(0);
                  setShowVideo(true);
                }}>
                <EyeIcon className="inline-block w-5 h-5" />
                <span>Show course video</span>
              </Button>
            )}

            <Button
              type="button"
              className="inline-flex justify-center items-center space-x-2 mt-2"
              onClick={() => openNewTab(course.manualUrl)}>
              <DownloadIcon className="inline-block w-5 h-5" />
              <span>Download course manual</span>
            </Button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CoursePage;
