import { ArrowRightIcon, PuzzleIcon, RefreshIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import PageHeader from 'components/SidebarLayout/PageHeader';
import VideoPlayer from 'components/VideoPlayer';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import courseVideo from 'media/course_video_sample.mp4';
import { CourseQuestion, CourseQuestionAnswer } from 'types/course';
import axios from 'axios';

interface RouteState {
  questions: CourseQuestion[];
}

interface Params {
  sku: string;
  orderNumber: string;
}

const correctAnswerPredicate = (answer: CourseQuestionAnswer) => !!answer.selected === answer.isRight;
const wrongAnswerPredicate = (answer: CourseQuestionAnswer) => !!answer.selected !== answer.isRight;

const QuizResultsPage = () => {
  const { push } = useHistory();
  const { state } = useLocation<RouteState>();
  const { sku, orderNumber } = useParams<Params>();
  const [currentlyPlayingQuestionId, setCurrentlyPlayingQuestionId] = useState<string>();

  const questions = state?.questions;
  const correctQuestionsCount = questions?.filter((question) => question.answers.every(correctAnswerPredicate))?.length;
  const totalQuestionsCount = questions?.length;
  // TODO: Hardcoded 80% for now, later will get from API
  const quizPassed = correctQuestionsCount / totalQuestionsCount >= 0.8;
  const questionsWithWrongAnswers = questions?.filter((question) => question.answers.some(wrongAnswerPredicate));

  const videoPath = `/static/media/course/${sku}.mp4`;

  useEffect(() => {
    if (sku) {
      axios.post(`course/score/${sku}/${correctQuestionsCount}`);
    }
  }, [correctQuestionsCount, sku]);

  if (!questions || questions.length === 0 || !sku) return <Fragment />;

  return (
    <Fragment>
      <PageHeader Icon={PuzzleIcon}>Course Quiz Results</PageHeader>

      {quizPassed && (
        <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden mb-8 border">
          <div className="p-5 lg:p-6 flex-grow w-full border-l-4 border-green-300 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between sm:space-x-2">
            <div className="">
              <h3 className="text-lg font-semibold mb-1">Congrats you have passed the quiz!</h3>
              <h3 className="text-lg font-semibold1 mb-1">
                Your score:{' '}
                <span className="text-xl font-bold text-green-300">
                  {correctQuestionsCount}/{totalQuestionsCount}
                </span>
              </h3>
              <p className="text-gray-500">Now you can fill out the survey and then get access to your certificate!</p>
            </div>
            <div className="flex-none">
              <button
                type="submit"
                className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-4 py-3 leading-6 rounded border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200"
                onClick={() => push(`/course/${sku}/survey/${orderNumber}`)}>
                <span className="">Fill out the survey</span>
                <ArrowRightIcon className="inline-block w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      )}

      {!quizPassed && (
        <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden mb-8 border">
          <div className="p-5 lg:p-6 flex-grow w-full border-l-4 border-orange-300 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between sm:space-x-2">
            <div className="">
              <h3 className="text-lg font-semibold mb-1">Unfortunately you did not pass the quiz!</h3>
              <h3 className="text-lg font-semibold1 mb-1">
                Your score:{' '}
                <span className="text-xl font-bold text-orange-300">
                  {correctQuestionsCount}/{totalQuestionsCount}
                </span>
              </h3>
              <p className="text-gray-500">
                Don't worry, you can still retake the quiz, below you can see what questions you had trouble with!
              </p>
            </div>
            <div className="flex-none">
              <button
                type="submit"
                className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-4 py-3 leading-6 rounded border-orange-200 bg-orange-200 text-orange-700 hover:text-orange-700 hover:bg-orange-300 hover:border-orange-300 focus:ring focus:ring-orange-500 focus:ring-opacity-50 active:bg-orange-200"
                onClick={() => push(`/course/${sku}/quiz/${orderNumber}`)}>
                <RefreshIcon className="inline-block w-5 h-5" />
                <span className="">Retake the quiz</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {!quizPassed && (
        <Fragment>
          <h2 className="text-xl font-semibold my-4">Your answers</h2>
          <div className="space-y-4">
            {questions.map((question, i) => {
              return (
                <QuizAnswerResult
                  question={question}
                  currentlyPlayingQuestionId={currentlyPlayingQuestionId}
                  onVideoOpen={(questionId) => setCurrentlyPlayingQuestionId(questionId)}
                  key={i}
                />
              );
            })}
          </div>
        </Fragment>
      )}

      {quizPassed && questionsWithWrongAnswers.length > 0 && (
        <Fragment>
          <h2 className="text-xl font-semibold my-4">Incorrect answer(s)</h2>
          <div className="space-y-4">
            {questionsWithWrongAnswers.map((question, i) => {
              return (
                <QuizAnswerResult
                  question={question}
                  currentlyPlayingQuestionId={currentlyPlayingQuestionId}
                  onVideoOpen={(questionId) => setCurrentlyPlayingQuestionId(questionId)}
                  key={i}
                />
              );
            })}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default QuizResultsPage;

const QuizAnswerResult = (props: {
  question: CourseQuestion;
  currentlyPlayingQuestionId?: string;
  onVideoOpen: (questionId: string) => void;
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const isCorrect = props.question.answers.every(correctAnswerPredicate);
  const { sku } = useParams<Params>();
  const videoPath = `/static/media/course/${sku}.mp4`;

  useEffect(() => {
    if (props.currentlyPlayingQuestionId !== props.question.id) {
      setShowVideo(false);
    }
  }, [props.currentlyPlayingQuestionId, props.question.id]);

  return (
    <div
      className={classNames('space-y-4 xl:w-3/4 2xl:w-3/5 rounded border bg-white p-4', {
        'border-green-300 hover:border-green-600': isCorrect,
        'border-red-300 hover:border-red-600': !isCorrect
      })}>
      <div className="flex">
        {isCorrect && <CheckCircleIcon className="w-6 h-6 flex-none mt-1 text-green-500" />}
        {!isCorrect && <XCircleIcon className="w-6 h-6 flex-none mt-1 text-red-500" />}
        <div className="font-medium sm:text-lg ml-2">{`${props.question.sortNumber}. ${props.question.text}`}</div>
      </div>
      <div className="space-y-2 pl-10">
        {props.question.answers
          .filter((answer) => answer.selected)
          .map((answer) => {
            return (
              <div
                className={classNames('flex items-center')}
                key={`question-${props.question.sortNumber}-answer-${answer.id}`}>
                {answer.text}
              </div>
            );
          })}
      </div>
      {!isCorrect && (
        <Fragment>
          {!showVideo && (
            <button
              onClick={() => {
                setShowVideo(true);
                props.onVideoOpen(props.question.id);
              }}
              className="block pl-8 font-semibold focus:outline-none leading-5 rounded border-transparent text-blue-600 hover:text-blue-400  active:text-blue-600">
              Reference this question in the video
            </button>
          )}

          {showVideo && (
            <div className="shadow-xl mx-4 sm:mx-12 md:mx-24">
              <br />
              <VideoPlayer src={videoPath} timestamp={props.question.timestamp} />
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
