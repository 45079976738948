import React from 'react';

interface Props {
  Icon?: React.ComponentType<any>;
  subTitle?: React.ReactNode;
  children: React.ReactNode;
}

const PageHeader: React.FunctionComponent<Props> = ({ children, Icon, subTitle }) => {
  return (
    <h1 className="text-2xl font-bold pb-2 border-b-2 border-gray-200 mb-4 lg:mb-8">
      <span className="flex">
        {Icon && (
          <span>
            <Icon className="inline-block w-7 h-7 opacity-50 mr-3" />
          </span>
        )}
        {children}
      </span>

      {subTitle && <span className="block text-lg text-gray-600 font-normal">{subTitle}</span>}
    </h1>
  );
};

export default PageHeader;
