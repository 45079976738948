import React, { Fragment } from 'react';
import cx from 'classnames/dedupe';
import usePageLoad from './usePageLoad';
import Loader from 'components/Loader';

const PageLoadingArea = ({ children, className, ...rest }: React.PropsWithChildren<JSX.IntrinsicElements['div']>) => {
  const { isLoading } = usePageLoad();

  return (
    <Fragment>
      {isLoading && <Loader />}
      <div className={cx(className, 'invisible', { invisible: isLoading, 'fade-in': !isLoading })} {...rest}>
        {children}
      </div>
    </Fragment>
  );
};

export default PageLoadingArea;
