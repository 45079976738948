import React from 'react';
import useOnOutsideClick from 'utilities/useOnOutsideClick';

interface MenuItem {
  name: string;
  url: string;
}

interface Props {
  id: string;
  onClose: (evt: any) => void;
  menuItems: MenuItem[];
}

const DropdownMenu = (props: Props) => {
  useOnOutsideClick(`#${props.id}`, props.onClose);

  return (
    <div
      role="menu"
      className="bg-white absolute left-0 origin-top-left shadow-xl rounded z-100 ml-14 border border-gray-300">
      <div className="bg-blue-50  bg-opacity-40 ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100">
        <div className="p-2 space-y-1">
          {props.menuItems.map((menuitem) => {
            return (
              <a
                key={menuitem.name}
                href={menuitem.url}
                target="blank"
                className="block rounded py-2 px-3 font-medium whitespace-nowrap text-gray-700 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 focus:text-gray-700">
                {menuitem.name}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
